/**
/* © 2023 University of Cambridge. All rights reserved.  
**/
import { Checkbox, Collapse, FormControl, FormControlLabel, FormLabel, Grid,
    IconButton,
    InputLabel, List, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup,
    Select, SelectChangeEvent, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { SyntheticEvent } from "react";
import SelectAge from "./Age";
import { Person } from "./Person";
import MyTooltip from "./MyTooltip";
import { Cancer } from "../utils/Cancers";

interface CancersPanelProps {
  question: string;
  person: Person;
}
  
function getMinMax(isBC2:boolean, person: Person) {
  let minAge = isBC2 && person.cancers.Breast ? person.cancers.Breast.age : undefined;
  let maxAge = person.age > 0 ? person.age : undefined;
  if(minAge && maxAge && minAge > maxAge) minAge = 0;
  return {minAge:minAge, maxAge:maxAge}
}

/**
 * Cancer Diagnoses Panel
 */
export default function CancersPanel(props: CancersPanelProps) {
  const person = props.person;
  const cancers = Cancer.getCancersBySex(person.sex);
  let isDiagnosed = false;
  for (const c of cancers) { if(person.cancers[c.cancer]){isDiagnosed = true; break;} }
  const [open, setOpen] = React.useState(isDiagnosed||props.person.cancersQ==="Y");

  return (
    <List>
      <FormControl sx={{ml:1}}>
        <FormLabel id="radio-btn-grp-cancer">
          {props.question}

          <MyTooltip
            outline={false}
            title={"To conduct the most accurate risk assessment, it is important to provide "+
                  "information about cancer diagnoses for you and your family. \n\n"+
                  "We can only include cancer diagnoses in your risk assessment if you "+
                  "include age at diagnosis and year of birth for that person. If you are "+
                  "not able to give the exact information, please give an estimate. "+
                  "If the type of cancer is unknown, please select 'Other' in the list of cancers below."}
            areaLabel={"Cancer diagnoses button"}/>
        </FormLabel>
        <RadioGroup
          row
          key={props.person.cancersQ}
          aria-labelledby="radio-btn-grp-cancer"
          defaultValue={props.person.cancersQ}
          name="c-grp"
          onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            props.person.cancersQ = value as "U"|"N"|"Y";
            setOpen(value === "Y");
            if(value !== "Y") person.cancers = {};
          }}
        >
          <FormControlLabel value="Y" control={<Radio size="small" />} label="Yes" />
          <FormControlLabel value="N" control={<Radio size="small" />} label="No" />
          <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />

        </RadioGroup>
      </FormControl>

      <Collapse in={open} timeout="auto" unmountOnExit>
          <MultipleSelectCancers person={person} />
      </Collapse>
    </List>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultipleSelectCancersProps {
  person: Person;
}

function MultipleSelectCancers(props:MultipleSelectCancersProps) {
  const cancerNames = Cancer.getCancersBySex(props.person.sex).map((c:Cancer) => c.cancer);
  const myCancer = Cancer.getMyCancers(props.person);
  const [cancerName, setCancerName] = React.useState<string[]>(myCancer.map((c:Cancer) => c.cancer));
  const [cancers, setCancers] = React.useState<Cancer[]>(myCancer);

  const smScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const onChange = (event: SelectChangeEvent<typeof cancerName>) => {
    const {target: { value },} = event;
    setCancerName(typeof value === 'string' ? value.split(',') : value,);
  };

  function updateSelectedCancers(cNames: string[]): void {
    let currentCancers:Cancer[] = [];
    for(let i = 0; i < cNames.length; i++) {
      currentCancers.push(new Cancer(cNames[i]));
      if(!person.cancers.hasOwnProperty(cNames[i]))
        person.cancers[cNames[i]] = {'age':-1};
    }
    setCancers(currentCancers);
  }

  function onClose(event: SyntheticEvent<Element, Event>): void {
    updateSelectedCancers(cancerName);
  }

  function onDelete(deleteCancer:string): void {
    const index = cancerName.indexOf(deleteCancer);
    if (index > -1) {
      delete person.cancers[deleteCancer];
      cancerName.splice(index, 1);
      setCancerName(cancerName); 
      updateSelectedCancers(cancerName);
    }
  }

  const person = props.person;
  const myCancers = cancers.map((myCancer, idx) => {
  const c = myCancer.cancer;
    return (
        <CancerInput
          key={person.uid+c}
          cancer={myCancer}
          person={person}
          flexStart={smScreen}
          ageChange={(a) => {
            if(a) {
              if(person.cancers.hasOwnProperty(c))
                person.cancers[c]['age'] = parseInt(a);
              else
                person.cancers[c] = {'age':parseInt(a)};
            } else {
              delete person.cancers[c];
            }
          }}
          onDelete={onDelete}
        />
    )
  })

  return (
    <>
      <Grid container spacing={0} >
          <Grid item sm={6} xs={12}>
              <FormControl sx={{ m: 1, width: "100%", pb:1 }} size="small">
                  <InputLabel id="multiple-cancers-label">Select Diagnosed Cancer(s)</InputLabel>
                  <Select
                  labelId="multiple-cancers-label"
                  id="multiple-cancers"
                  multiple
                  value={cancerName}
                  onChange={onChange}
                  onClose={onClose}
                  input={<OutlinedInput label="Select Diagnosed Cancer(s)" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  sx={{mr:1}}
                  >
                  {cancerNames.map((name) => (
                      <MenuItem key={name} value={name} sx={{py:0.1}} dense={true}>
                      <Checkbox sx={{py:0.5}} checked={cancerName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                      </MenuItem>
                  ))}
                  </Select>
              </FormControl>
            </Grid>
      </Grid>
      {myCancers.length > 0 && 
              <Typography variant="body1" sx={{pl:1, mb:2}}>Enter the age of diagnosis (if unknown please give a rough estimate):</Typography>}
      {myCancers}
    </>
  );
}

interface CancerInputProps {
  cancer:Cancer;
  person:Person;
  ageChange:(a: string|null) => void;
  onDelete:(cName: string) => void;
  flexStart: boolean
}
  
function CancerInput(props:CancerInputProps) {
  const person = props.person;
  const c = props.cancer;

  const [age, setAge] = React.useState(person.cancers[c.cancer] ? person.cancers[c.cancer].age: 0);

  const label = c.cancer !== "Opposite Breast" ? c.cancer + " Cancer" : "Cancer in Opposite Breast";
  const isBC2 = c.cancer === "Opposite Breast";
  let range = getMinMax(isBC2, person);
  const labelStyle = (!props.flexStart ? { display: "flex", justifyContent: "flex-end", mr:0.5 } : 
                                         { display: "flex", justifyContent: "flex-start", mr:0 })

  return (
    <Grid container spacing={0} key={c.cancer} >
      <Grid item sm={6} xs={12} sx={labelStyle}>
        { c.cancer === "Other" ? <OtherCancerInput person={person} /> : <Typography variant="body1" sx={{mt:1}}>{label}</Typography>}
      </Grid>
      <Grid item sm={5} xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems:"center" }}>
        <FormControl sx={{mr:1, mt:0.25}}>
          <SelectAge age={age}
                ageChange={(a) => {
                  if(a) setAge(parseInt(a));
                  props.ageChange(a);
                  range = getMinMax(isBC2, person);
                }}
                id={c.cancer.replace(/\s/g, '')+"-diagnosis-age"}
                label="Diagnosis age"
                required={false}
                minAge={range.minAge}
                maxAge={range.maxAge}/>
        </FormControl>

        <IconButton
          aria-label="delete"
          size="small" 
          title={"Remove "+c.cancer+" Cancer Diagnosis"}
          onClick={() => {props.onDelete(c.cancer)}}>
          <DeleteIcon color="secondary"/>
        </IconButton>

      </Grid>
    </Grid>
  )
}

interface OtherCancerInputProps {
  person: Person;
}

function OtherCancerInput(props:OtherCancerInputProps) {
  const person = props.person;
  const myCancers = person.cancers;
  const [otherCancer, setOtherCancer] = React.useState<string|undefined>(myCancers.Other ? myCancers.Other.otherCancerName : undefined);
  
  function updateCancerName(cname:string) {
      if(!myCancers.Other)
          myCancers["Other"] = {'age':-1, 'otherCancerName':cname};
      else
          myCancers.Other.otherCancerName = cname;
      setOtherCancer(cname);
  }
  
  return (
    <TextField
      id={person.uid+"OtherCancer"}
      defaultValue={otherCancer}
      fullWidth
      key={person.uid+"OtherCancer"}
      label="Enter cancer name if known" type="string"
      size="small"
      sx={{mt:0.25}}
      onBlur={() => {
          const e = document.getElementById(person.uid+"OtherCancer") as HTMLInputElement;
          updateCancerName(e?.value.trim())
      }}
      onChange={function(event: React.ChangeEvent<HTMLInputElement>) {
          updateCancerName(event.target.value.trim())
      }}
      //InputLabelProps={{ shrink: true }}
    />
  )
}

interface CancerRerportProps {
  person: Person;
}

export function CancerRerport(props:CancerRerportProps) {
  const ps = props.person;
  const mycancers = Cancer.getMyCancers(ps);

  const cs = mycancers.map((c, idx) => {
    const mycancer = ps.cancers[c.cancer];
    const cancerType = (c.cancer !== "Other" ? c.cancer+" Cancer" : mycancer.otherCancerName);
    const cancerAge = (mycancer.age > -1 ? mycancer.age : "unknown");
    return (
      <Grid container spacing={0.1} key={c.cancer}>
        <Grid item xs={12}>
          <Typography>{cancerType} (age {cancerAge})</Typography>
        </Grid>
      </Grid>
  )
  });
  return <>{cs.length > 0 ? cs: <Typography variant="body1">None</Typography>}</>;
}
