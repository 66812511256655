/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React, { useRef, useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, FormControlLabel, FormGroup, FormLabel,
    Typography, useMediaQuery, useTheme } from "@mui/material";
import { getFT, Person } from "./Person";
import { pink } from "@mui/material/colors";
import MyTooltip from "./MyTooltip";


/**
 * Identical siblings
 */
 export interface IdenticalSiblings {
    key: string;
    name: string;
    siblings: Person[];
}

export function detectIdenticalSiblings() {
    const {proband, mother, father, kids, sibs, mothers_sibs, fathers_sibs} = getFT();
    const rels = kids.concat(sibs, mothers_sibs, fathers_sibs);
    const identicalSiblings:IdenticalSiblings[] = [];

    for(let i in rels) {
        const n = rels[i].name;
        const p = rels[i].persons;
        if(p === undefined) continue;

        let o = undefined;
        // assume siblings of same sex might be identical
        if(n === "mother's sisters" && mother.age > 0)
            o = mother;
        else if(n === "mother's brothers" && mother.age > 0)
            o = mother;
        else if(n === "father's brothers" && father.age > 0)
            o = father;
        else if(n === "father's sisters" && father.age > 0)
            o = father;
        else if(n === "sisters" && proband.age > 0) 
            o = proband;
        else if(n === "brothers" && proband.age > 0)
            o = proband;

        //if(o === undefined) continue;
        let oHasPossibleTwin = false;
        for (let j = 0; j < p.length; j++) {
            let pj = p[j];
            if(pj.age === 0 || isNaN(pj.yob) || pj.yob === 0) continue;
            let pjHasPossibleTwin = false;
            if(o && pj.yob === o.yob && pj.sex === o.sex) {
                identicalSiblings.push({key:i+"_"+j, name:n, siblings:[o, pj]});
                oHasPossibleTwin = true;
                pjHasPossibleTwin = true;
            } 

            for(let k = 0; k < p.length; k++) {
                if(j === k) continue;
                if(pj.yob === p[k].yob) {
                    if(k > j) identicalSiblings.push({key:i+"_"+j+"_"+k, name:n, siblings:[pj, p[k]]});
                    pjHasPossibleTwin = true;
                }
            }
            if(!pjHasPossibleTwin && pj.mztwin !== '0') {
                console.error("INCORRECTLY MARKED MZTWIN", pj);
                pj.mztwin = '0';
            }
        }
        if(o && !oHasPossibleTwin && o.mztwin !== '0') {
            console.error("INCORRECTLY MARKED MZTWIN", p);
            o.mztwin = '0';
        }
    }
    return identicalSiblings;
}


type TwinType = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "A";

// get a new unique twins ID, max of 10 twins in a pedigree
function getUniqueTwinID(identicalSiblings:IdenticalSiblings[]): TwinType {
	const mz:TwinType[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "A"];
	for(let i=0; i<identicalSiblings.length; i++) {
        let idx = -1
		if(identicalSiblings[i].siblings.length > 0) {
			idx = mz.indexOf(identicalSiblings[i].siblings[0].mztwin);
		}
        if (idx > -1) mz.splice(idx, 1);
	}
	if(mz.length > 0)
		return mz[0];
	return "0";
}


/**
 * Twin panel
 */
 export default function TwinPanel(props: {onTwinUpdate: () => void}) {
    const identicalSibs = detectIdenticalSiblings();
    console.log("IDENTICAL TWINS", identicalSibs.length, identicalSibs);

    const [open, setOpen] = useState(identicalSibs.length !== 0);
    const twinRef = useRef(null);
    const theme = useTheme();
    const fullScreen = !useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (_event?: any, reason?: string) => {
        if (reason && reason === "backdropClick") 
            return;
        setOpen(false);
        props.onTwinUpdate();
    };

    let possibleTwins = identicalSibs.map((identisibs) => {
        if(!identisibs.siblings)
            return (<></>);
        const siblings = identisibs.siblings;
        const yob = siblings[0].yob;

        return (
            <FormControlLabel
                control={<Checkbox defaultChecked={identisibs.siblings[0].mztwin !== "0"}
                          sx={{color: theme.palette.primary.main, '&.Mui-checked': {color: pink[600]}}}/>}
                label={identisibs.name+" (born in "+yob+")"}
                labelPlacement="start"
                key={identisibs.key+identisibs.siblings[0].mztwin}
                onChange={function(event: React.SyntheticEvent) {
                    const isChecked = (event.target as HTMLInputElement ).checked;
                    let mz:TwinType = "0";
                    if(siblings.length > 0 && isChecked) {
                        mz = siblings[0].mztwin;
                        if(mz === "0") mz = getUniqueTwinID(identicalSibs);
                    }
                    for(let i = 0; i < siblings.length; i++) {
                        siblings[i].mztwin = mz;
                    }
                    console.log("MZTWINS", mz);
                }}
            />
            )
    })
    
    return (
        <Dialog
            ref={twinRef}
            fullScreen={!fullScreen}
            open={open}
            aria-labelledby="identical-siblings-dialog-title"
        >
            <DialogTitle>Possible Identical Twins</DialogTitle>

            <DialogContent>
                <Typography variant="body1">
                    Some family members, that are siblings, are born in the same year, so may be identical twins or triplets.
                    <MyTooltip 
                        title="The chances of having non-identical (also known as fraternal) twins/triplets is higher than them being identical."
                        areaLabel="Metric/Imperial units information button"
                        color="primary"
                    />
                </Typography>

                <Box sx={{mt:1, backgroundColor: '#F0F0F0', borderRadius: 2}}>
                    <FormControl component="fieldset">
                        <FormLabel sx={{p:2}} component="legend">Please select any identical multiple births (i.e. identical twins/triplets):</FormLabel>
                        <FormGroup aria-label="position">
                            {possibleTwins}
                        </FormGroup>
                    </FormControl>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="contained" fullWidth={true} color="secondary" autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
  }
