/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import metadata from '../metadata.json';

export default function Footer() {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box component="footer" borderTop={1} sx={{ py: 3,  px: 2, mt: 'auto' }}>
            <img src={'/img/camuni_logo.jpg'} alt="University of Cambridge" width="160" />

            <Typography variant="subtitle2" component={smScreen ? "p": "span"} sx={{float:smScreen ? "none": "right"}}>
                Copyright &copy;2025 University of Cambridge<br />
                {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
            </Typography>
        </Box>
    )
}
