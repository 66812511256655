/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import { Person } from "../components/Person";
import { Cancer } from "./Cancers";

export type answerType = "Y"|"N"|"U";

/**
 * Scroll the browser window to the top
 */
export const scrollToTop = () => {
    setTimeout(function() {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // scroll to top of page
    }, 500) //execute after .5 second
}

/** Make a unique ID */
export const  makeid = (length:number=3) => {
    let r = '';
    const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const l = c.length;
    for (let i = 0; i < length; i++) {
        r += c.charAt(Math.floor(Math.random() * l));
    }
    return r;
}

/**
 * Get a URL parameter
 * @param name - URL parameter name
 * @param defaultValue 
 * @returns 
 */
export const urlParam = (name: string, defaultValue:string|undefined=undefined): string|undefined => {
	const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
	if (results===null)
	   return defaultValue;
	else
	   return results[1] || defaultValue;
}

/**
 * Get the age from the birth date and, if deceased, date of death
 * @param birthDate 
 * @param deathDate 
 * @returns 
 */
export const getAge = (birthDate:Date, deathDate?:Date) => { // birthday is a date
    const today = (deathDate ? deathDate : new Date());
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
}

const removeChildNodes = (n:HTMLElement) => {
    while (n?.firstChild && n.lastChild) {
      n.removeChild(n.lastChild);
    }
}

/**
 * Clear contents of an element
 * @param id ID of the element
 * @returns 
 */
export const  clearContents = (id:string) => {
    const theDiv = document.getElementById(id);
    if(theDiv) removeChildNodes(theDiv);
    return theDiv;
}

/** 
 * Check token is valid format, e.g.00119890001BRIX 
 */
export const isValidToken = (token:string) => {
    const expression: RegExp = /^[0-9]{11}[A-Z]{4}$/;
    return expression.test(token);
}

/** 
 * Convert token, e.g.00119890001BRIX from 
 * 001 <PID> + 
 * 1989 = YYYY 
 * 0001 = <PPT>
 * BRI = Surname 
 * X = Check Letter
 * StudyID is of the form 001-0001-X 
*/
export const  getTokenParts = (tok?:string) => {
    const token = (tok ? tok : urlParam("tok"));
    if(!token) return undefined;

    if(!isValidToken(token)) {
        return false;
    }

    const pid = token?.substring(0, 3);
    const yob = token?.substring(3, 7);
    const ppt = token?.substring(7, 11);
    const sur = token?.substring(11, 14);
    const chk = token?.substring(14);
    return {
        token: token,
        pid: pid,
        yob: yob,
        ppt: ppt,
        sur: sur,
        studyID: pid+"-"+ppt+"-"+chk
    }
}

/**
 * Convert token, e.g.00119890001CAMX from 
 * @param studyid - studyID is of the form 001-0001-X 
 * @param surname - surname e.g. Campbell
 * @param yob - year of birth
 * @returns 
 */
export const getToken = (studyid: string, surname: string, yob: string) => {
    const parts = studyid.split("-");
    let name = surname.substring(0, 3);
    if(name.length < 3) name+="Z";  // allow for two letter surnames
    return parts[0] + yob + parts[1] +name.toUpperCase() + parts[2];
}

/**
 * Join array of strings with commas (,) and "and".
 *
 * @example input ['one, 'two', 'three'];
 * @example return "one, two, and three"
 *
 * @param {string[]} array of strings.
 * @return {string} string created by joining the 
 *                  array with commas and "and".
 */
function addCommasAndAnd(list: string[]) {
    if (list.length < 3) { return list.join(' and '); }
  
    return `${list.slice(0, - 1).join(', ')}, and ${list[list.length - 1]}`;
  };

/** 
 * Ensure age/yob provided when diagnosed with cancer(s) and
 * that age of diagnosis is greater or equal to their age. In addition,
 * check that the age of diagnosis is provided.
 */
export function isValidAgesMsg(ps:Person) {
    let msg = "";
    const mycancers = Cancer.getMyCancers(ps);
    if(mycancers.length !== 0 && (!ps.yob || !ps.age)) {
        msg = "Ensure that the age and year of birth are provided. ";
    } else {
        let dage = [];
        for(let i =0; i<mycancers.length; i++) {
            let cage = ps.cancers[mycancers[i].cancer].age;
            if(cage && ps.age < cage) {
                msg += mycancers[i].cancer +" cancer diagnosis age ("+cage+") should be less than or equal to their age ("+ps.age+"). "
            } else if(cage === -1) {
                dage.push(mycancers[i].cancer.toLowerCase()+" cancer")
            }
        }
        if(dage.length > 0) {
            msg += "Please provide the age of diagnosis for the "+addCommasAndAnd(dage)+".";
        }
    }
    return msg;
}
